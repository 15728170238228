@import '../../styles/variables';
@import '../../styles/mixin';

.root_container {
  font-family: 'Noto Sans JP', sans-serif;
  min-width: 99vw;
  width: fit-content;
  min-height: 100vh;
  overflow: auto;
  font-weight: 400;
  scrollbar-color: $grey4 $alto-color;
  scrollbar-width: thin;
  background-color: $page-bg-color;
  @include respond(tab-land) {
    width: 100%;
    min-width: unset;
  }

  &::-webkit-scrollbar-track {
    background-color: $alto-color;
  }

  &::-webkit-scrollbar {
    height: 7px;
    width: 7px;
    background-color: $alto-color;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $grey4;
  }
}

.mobile {
  overflow-x: hidden;
}
