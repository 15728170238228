@import '../../styles/variables';
@import '../../styles/mixin';

.header {
  background-color: $white-bg-color;
  height: $header-height;
  padding: 0px 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include respond(tab-land) {
    width: 100%;
    padding: 0 10px;
  }
  @include respond(phone) {
    padding: 0px 10px;
    flex-wrap: wrap;
  }
}

.search {
  width: 334px;

  :global {
    .ant-select-selector {
      height: 40px !important;
    }
    .ant-select-selection-placeholder {
      line-height: 40px !important;
    }
    .ant-select-selection-item {
      line-height: 40px !important;
    }
  }

  @include respond(tab-land) {
    width: 200px;
    :global {
      .ant-select-selector {
        height: 30px !important;
      }
      .ant-select-selection-placeholder {
        line-height: 30px !important;
      }
      .ant-select-selection-item {
        line-height: 30px !important;
      }
    }
  }
  @include respond(phone) {
    width: 120px;
    :global {
      .ant-select-selector {
        height: 26px !important;
      }
      .ant-select-selection-placeholder {
        line-height: 26px !important;
      }
      .ant-select-selection-item {
        line-height: 26px !important;
      }
    }
  }
}

.setting__icon {
  color: $alto-color;
  &:hover {
    color: $curious-blue;
  }
}

.logo {
  width: 320px;
  @include respond(tab-land) {
    width: 200px;
  }
  @include respond(tab-port) {
    width: 125px;
  }
  @include respond(phone) {
    width: 150px;
  }
}

.search_container {
  display: flex;
  align-items: center;
  @include respond(tab-port) {
    flex: 1;
    justify-content: flex-end;
  }
}

.btn_logout {
  font-size: 18px;
  width: fit-content;
  padding-left: 30px;
  padding-right: 30px;
  margin-right: 16px;
  margin-left: 5px;
  @include respond(phone) {
    margin-right: 10px;
    margin-left: 2px;
    padding-left: 20px;
    padding-right: 20px;
  }
}
