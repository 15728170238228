$header-height: 71px;
$screen-width: 1280px;
$footer-height: 280px;
$yellow-color: #f5c924;
$yellow1: #f6c626;
$yellow2: #e1ad36;
$yellow3: #ffed64;
$linear-yellow: linear-gradient(#f6c626, #f1dc1f);

$zebra-bg-color: #f2f3f5;
$position-select-bg: #9a9b9d;
$orange1: #d84b3d;
$grey1: #707070;
$grey2: #888888;
$grey3: #8b8b8b;
$grey4: #b2b2b2;
$grey5: #c4c4c4;
$grey6: #757575;
$grey7: #a5a5a5;
$grey8: #d1cdcd;
$grey9: #e2e2e2;
$red1: #b92626;
$red2: #bf3a47;
$red3: #cd7a7a;
$red4: #df3e2b;
$blue1: #1a6dcc;
$blue2: #1087d6;
$blue3: #264dcf;
$blue4: #1d9bf0;
$blue5: #56d9f3;
$blue6: #3eade9;
$blue7: #2ba7d1;
$green1: #5bbc60;
$green2: #44ccd1;
$green3: #3bbf37;

$white-color: #fff;
$black-color: #000;
$white-bg-color: #fafbfc;
$milk-punch-color: #fff7d8;
$tundora-color: #444141;
$alto-color: #dcdcdc;
$valencia-color: #d84b3d;
$cupid-color: #fbc6c6;
$curious-blue: #2481df;
$page-bg-color: #f1f1f1;
$border-btn-header-color: #146bc2;

.color-white {
  color: #fff;
}

.bg-white1 {
  background-color: #fff;
}

.bg-white-2 {
  background-color: $white-bg-color;
}

.bg-yellow {
  background-color: $yellow-color;
}

.bg-yellow3 {
  background-color: $yellow3;
}

.bg-orange1 {
  background: $orange1 !important;
}

.bg-grey1 {
  background-color: $grey1;
}

.bg-grey3 {
  background-color: $grey3;
}

.bg-grey4 {
  background-color: $grey4;
}

.bg-grey5 {
  background-color: $grey5;
}

.bg-grey7 {
  background-color: $grey7;
}

.bg-grey9 {
  background-color: $grey9;
}

.bg-curious-blue {
  background: $curious-blue !important;
}

.bg-alto-color {
  background-color: $alto-color;
}

.bg-page {
  background-color: $page-bg-color !important;
}

.bg-cupid {
  background-color: $cupid-color;
}

.bg-blue3 {
  background-color: $blue3;
}

.bg-blue4 {
  background-color: $blue4;
}

.bg-blue5 {
  background-color: $blue5;
}

.bg-blue6 {
  background-color: $blue6;
}

.bg-blue7 {
  background-color: $blue7;
}

.bg-tundora {
  background: $tundora-color;
}

.color-red1 {
  color: $red1 !important;
}

.color-red2 {
  color: $red2;
}

.color-red3 {
  color: $red3;
}

.color-red4 {
  color: $red4;
}

.color-grey2 {
  color: $grey2;
}

.color-grey4 {
  color: $grey4;
}

.color-blue1 {
  color: $blue1;
}

.color-blue2 {
  color: $blue2;
}

.color-green1 {
  color: $green1;
}

.bg-green2 {
  background-color: $green2;
}

.bg-green3 {
  background-color: $green3;
}

.outline-curious-blue {
  border-color: $curious-blue !important;
  color: $curious-blue !important;
}

.color-link {
  a {
    color: #1a6dcc !important;
  }
}

.color-minus {
  color: $red1;
}

.color-total {
  color: #252526;
}
