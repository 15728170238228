@import '../../styles/variables';

.modalBody {
  padding: 40px 75px 35px;
  text-align: center;
  position: relative;
  font-size: 16px;
  font-family: 'Noto Sans JP', sans-serif;
}

.modal {
  :global .ant-modal-content {
    border-radius: 5px;
  }
}
