@mixin flex($align-item, $justify-content) {
  display: flex;
  align-items: $align-item;
  justify-content: $justify-content;
}

@mixin respond($breakpoint) {
  @if $breakpoint == phone {
    @media (max-width: 576px) {
      @content;
    }
  }
  @if $breakpoint == tab-port {
    @media (max-width: 768px) {
      @content;
    }
  }
  @if $breakpoint == tab-land {
    @media (max-width: 992px) {
      @content;
    }
  }
}
