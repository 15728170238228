@import '../../styles/variables';
@import '../../styles/mixin';

.footer {
  background-color: #fff;
  height: $footer-height;
  padding-left: 50px;
  display: flex;
  padding-top: 30px;
  @include respond(tab-land) {
    padding-left: 20px;
    padding-right: 20px;
  }
  @include respond(tab-port) {
    padding-left: 10px;
    padding-right: 10px;
  }
  @include respond(phone) {
    padding: 20px 15px 0;
  }
  &__content {
    // width: $screen-width;
    @include respond(tab-land) {
      font-size: 12px;
    }
  }
}

.link_container {
  &__link {
    color: $black-color;
    @include respond(tab-land) {
      font-size: 10px;
    }
    @include respond(phone) {
      font-size: 10px;
    }
  }
}

.divider {
  height: auto !important;
  margin: 0 24px !important;
  @include respond(tab-port) {
    margin: 0 12px !important;
  }
}
