@import '../../styles/variables';
@import '../../styles/mixin';

.container {
  background-color: $curious-blue;
  height: 44px;
}

.toolbar {
  height: 100%;
  display: flex;
  margin: 0 auto;
  justify-content: center;
  background-color: $curious-blue;
  margin: 0 50px;
  @include respond(tab-port) {
    margin: 0 10px;
  }

  &__menu_item {
    transition: all ease-out 0.3s;
    :global {
      .anticon-down {
        font-size: 15px !important;
        transform: rotate(180deg) !important;
      }
    }
  }

  &__icon {
    height: 30px;
    margin-right: 4px;
    @include respond(phone) {
      height: 15px;
      margin-right: 0px;
    }
  }

  &__button {
    cursor: pointer;
    position: relative;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $white-color;
    text-transform: capitalize;
    font-size: 18px;
    font-weight: bold;
    @include respond(tab-port) {
      font-size: 14px;
    }
    @include respond(phone) {
      font-size: 12px;
    }
  }

  &__button_active {
    background-color: $white-bg-color;
    color: $black-color;
    :global {
      .anticon-down {
        transform: rotate(0deg) !important;
      }
    }
  }
  &__maintain {
    color: #145597 !important;
    cursor: unset;
  }
}

.dropdown {
  :global {
    .ant-dropdown-menu {
      background-color: $white-bg-color;
      padding: 0;
      color: $black-color;
    }
    .ant-dropdown-menu-item {
      height: 45px;
      text-align: center;
    }
    .ant-dropdown-menu-title-content > a {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.divider {
  width: 96% !important;
  background-color: $black-color !important;
  margin: 0 auto !important;
}

.divider_vertical {
  height: 80% !important;
  background-color: rgba($white-color, 0.55);
  margin: auto 0 !important;
}
