@import './mixin';
@import './variables';
@import './custom';

.content {
  min-height: calc(100vh - #{$header-height + $footer-height});
  background-color: $page-bg-color;
  display: flex;
  flex-direction: column;
}

.main-content {
  position: relative;
  min-width: 1280px;
  padding: 0 50px;
  width: fit-content;
  @include respond(tab-land) {
    min-width: unset;
    width: 100%;
    padding: 0 10px;
  }
}

.spin {
  padding: 30px 50px;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  height: 100%;
  min-height: calc(100vh - #{$header-height});

  display: flex;
  justify-content: center;
  align-items: center;
}

.spin-full {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.empty-full {
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.search {
  display: flex;
  margin-bottom: 16px;
  align-items: center;
  @include respond(tab-land) {
    flex-wrap: wrap;
  }
  & > *:not(:last-child) {
    margin-right: 8px;
    @include respond(tab-land) {
      margin-right: unset;
    }
  }
  &__select {
    flex-basis: 300px;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.footer-modal {
  display: flex;
  justify-content: flex-end;
  &__item:not(:last-child) {
    margin-right: 8px;
  }
}

table tr:nth-child(2n + 1) td {
  background-color: $zebra-bg-color;
}

.ant-table-tbody > tr.ant-table-row:hover > td,
.ant-table-tbody > tr > td.ant-table-cell-row-hover {
  background: unset !important;
}

.ant-table-tbody > tr.ant-table-row:nth-child(2n + 1):hover > td,
.ant-table-tbody > tr:nth-child(2n + 1) > td.ant-table-cell-row-hover {
  background: $zebra-bg-color !important;
}

.ant-table-tbody > tr.bg-fa-player:hover > td,
.ant-table-tbody > tr.bg-fa-player > td.ant-table-cell-row-hover {
  background: $milk-punch-color !important;
}

.ant-table-tbody > tr.bg-fa-player:nth-child(2n + 1):hover > td,
.ant-table-tbody > tr.bg-fa-player:nth-child(2n + 1) > td.ant-table-cell-row-hover {
  background: $milk-punch-color !important;
}

.ant-table-wrapper {
  padding: 3px;
  background-color: #fff;
}

.ant-table-body {
  scrollbar-color: $grey4 $alto-color;
  scrollbar-width: thin;
  // overflow: auto !important;
  &::-webkit-scrollbar-track {
    background-color: $alto-color;
  }

  &::-webkit-scrollbar {
    height: 7px;
    width: 7px;
    background-color: $alto-color;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $grey4;
  }
}

.ant-table.ant-table-small {
  font-size: 12px;
}

.ant-table-cell {
  position: relative;
  font-weight: 400;
  text-align: center;
  min-width: 32px;
  &:not(:last-child) {
    border-right: 1px dashed $grey4;
  }
}

.ant-table.ant-table-small .ant-table-tbody > tr > td {
  padding: 3px 5px;
}

.ant-table-thead th.ant-table-column-sort {
  background-color: $curious-blue !important;
  color: #fff;
  font-weight: 900 !important;
  .ant-table-column-sorter {
    border-color: #fff;
  }
}

.ant-table-thead > tr > th {
  background-color: $alto-color !important;
  white-space: nowrap;
  width: fit-content;
  text-align: center;
  font-weight: 400;
  @include respond(tab-port) {
    width: max-content;
  }
}

.table-summary {
  & > td {
    color: #fff;
    background: $curious-blue !important;
  }
}

.ant-table-column-has-sorters:not(.ant-table-column-sort) {
  .ant-table-column-sorter {
    border-left: 2px solid $grey1;
    border-bottom: 2px solid $grey1;
    transform: rotate(-45deg);
    width: 8px;
    height: 8px;
    margin-left: 0;
  }
}

.ant-table-column-sorters {
  flex-direction: column;
  b {
    font-weight: 900;
  }
  .ant-table-column-sorter {
    position: relative;
    height: 6px;
    .ant-table-column-sorter-up {
      border-right: 2px solid $grey1;
      border-top: 2px solid $grey1;
      transform: rotate(-45deg);
      margin: 4px 3px 0 0;
      position: absolute;
      width: 8px;
      height: 8px;

      display: none;
    }
    .ant-table-column-sorter-down {
      position: absolute;
      border-left: 2px solid $grey1;
      border-bottom: 2px solid $grey1;
      transform: rotate(-45deg);
      margin: 0 3px 0 0;
      width: 8px;
      height: 8px;
      display: none;
    }
    .active {
      display: block;
      border-color: #fff;
    }
    svg {
      display: none;
    }
  }
}

.ant-table-content {
  scrollbar-color: $grey4 $alto-color;
  scrollbar-width: thin;
  &::-webkit-scrollbar-track {
    background-color: $alto-color;
  }

  &::-webkit-scrollbar {
    height: 7px;
    background-color: $alto-color;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $grey4;
  }
}

.ant-table-summary tr td {
  padding: 3px 5px !important;
}

.select-search {
  width: 100%;
  font-weight: bold;
  .ant-select {
    width: 100% !important;
  }

  &__fix_width {
    .ant-select {
      width: 300px !important;
      @include respond(tab-land) {
        width: 100% !important;
      }
    }
  }

  &__width-252 {
    .ant-select {
      width: 252px !important;
      @include respond(tab-land) {
        width: 100% !important;
      }
    }
  }
  .ant-select-selector {
    background: transparent !important;
    border: none !important;
    box-shadow: none !important;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector,
  .ant-select:not(.ant-select-customize-input):hover .ant-select-selector {
    background-color: $alto-color !important;
    border: 1px solid $alto-color !important;

    .ant-select-selection-placeholder {
      color: $black-color !important;
    }
  }
  .ant-select-arrow {
    color: $black-color !important;
  }

  .ant-select-selection-placeholder {
    color: $black-color !important;
  }

  .ant-select-single.ant-select-open .ant-select-selection-item {
    color: $black-color !important;
  }

  .ant-select-selection-item {
    font-weight: bold;
  }
}

.ant-select-item {
  font-weight: bold;
}

// .date-picker-custom {
.ant-picker-cell-today .ant-picker-cell-inner {
  color: $white-color !important;
  background-color: $grey3;
}

.ant-picker-cell-today .ant-picker-cell-inner::before {
  border-radius: 999px !important;
  // background-color: #000000;
  z-index: -1;
  border: none !important;
}
.ant-picker-cell-week {
  display: none !important;
}
.date-picker-custom__no_week_title {
  // tr th:first-child {
  //   display: none !important;
  // }
}
.ant-picker-week-panel-row-selected td {
  background-color: $yellow-color !important;
}
.ant-picker-week-panel-row-selected .ant-picker-cell-inner {
  color: $black-color !important;
}
.ant-picker-week-panel-row-selected .ant-picker-cell-inner {
  color: $white-color !important;
}
// }

.label-search {
  display: block;
  color: $white-color;
  height: 20px;
  font-size: 12px !important;
  margin-bottom: 0 !important;
  &__black {
    color: $black-color;
  }
}

.ant-btn {
  word-spacing: -2.5px;
}

.button-fill {
  border-width: 2px;
  font-size: 14px;
  border-radius: 3px;
  border-color: $border-btn-header-color;
  min-width: 150px;
  background-color: $curious-blue;
  padding: 3px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  height: 40px;
  &:hover {
    background-color: $border-btn-header-color !important;
    border-color: $border-btn-header-color;
  }
  @include respond(tab-land) {
    min-width: 100px;
    font-size: 14px !important;
    padding: 3px 10px;
    height: 100%;
  }
  @include respond(tab-port) {
    min-width: 100px;
    font-size: 14px !important;
    padding: 3px 10px;
    height: 100%;
  }
  @include respond(phone) {
    min-width: 60px;
    font-size: 10px !important;
    padding: 3px 10px;
    height: 100%;
  }
}

.button-yellow {
  @extend .button-fill;
  background: $linear-yellow !important;
  border-color: $yellow2 !important;
  background-color: $yellow2 !important;
  &:hover {
    background: $yellow2 !important;
    color: $black-color;
  }
}

.button-red {
  @extend .button-fill;
  border: none !important;
  background-color: $orange1 !important;
  &:hover {
    background-color: #a2291d !important;
  }
}

.button-outline {
  @extend .button-fill;
  background: #fff !important;
  color: $border-btn-header-color;
  &:hover {
    background-color: #dceeff !important;
    color: $border-btn-header-color;
  }
}

.button-small {
  padding: 3px 7px;
  min-width: 70px;
  font-size: 13px;
  height: 30px;
  font-weight: bold;
}

.select-small {
  height: 26px;
  width: 187px;
  border-radius: 3px;
  border: 1px solid $grey3;
  .ant-select-selector {
    height: 100% !important;
  }
  .ant-select-selection-search-input {
    height: 100% !important;
  }
  .ant-select-selection-item {
    line-height: 26px !important;
  }
  .ant-select-selection-placeholder {
    line-height: 26px !important;
  }
}

.tab-custom {
  .ant-tabs-nav-list {
    margin-bottom: 0;
  }
  .ant-tabs-nav {
    margin-bottom: 0 !important;
    border-bottom: none;
  }
  .ant-tabs-tab {
    font-size: 14px;
    font-weight: bold;
    margin: 0 !important;
    border-radius: 0 !important;
    border: none !important;
    background: #444141 !important;
    color: #fff;
    display: flex;
    justify-content: center;
    &:hover {
      color: #fff !important;
    }
  }
  .ant-tabs-tab-active {
    background: #fff !important;
    .ant-tabs-tab-btn {
      color: #000 !important;
    }
  }
  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-operations,
  .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-operations {
    visibility: hidden !important;
  }
  .ant-tabs-nav-more {
    display: none !important;
  }
}

.tab-full {
  .ant-tabs-nav-list {
    width: 100%;
  }
  .ant-tabs-tab {
    width: 100%;
    font-size: 18px;
    padding: 6px 16px !important;
  }
}

.tag {
  padding: 1px 21px;
  color: #fff;
  text-transform: uppercase;
  font-weight: bold;
  border-radius: 12px;
}

.d-flex {
  display: flex !important;
}

.j-center {
  justify-content: center;
}

.j-between {
  justify-content: space-between;
}

.j-end {
  justify-content: flex-end;
}

.al-center {
  align-items: center;
}

.al-end {
  align-items: flex-end;
}

.fd-column {
  flex-direction: column;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.flex-wrap {
  flex-wrap: wrap;
}

.pre-wrap {
  white-space: pre-wrap;
  word-break: break-all;
}

.btn-primary {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 155px;
  transition: all ease-out 0.5s;
  font-weight: bold !important;
  background-color: $curious-blue !important;
  border: 2px solid $border-btn-header-color !important;
  border-radius: 5px !important;
  color: $white-color !important;
  font-size: 18px;
}

.week {
  padding: 2px;
  &::after {
    content: '';
    color: #fff;
    position: absolute;
    top: 2px;
    left: 5px;
    width: 0;
    height: 17px;
    width: calc(100% - 10px);
    background-color: #9a9b9d !important;
    clip-path: polygon(calc(100% - 10px) 0%, 100% 50%, calc(100% - 10px) 100%, 0% 100%, 0 50%, 0% 0%);
    z-index: 0;
  }
  &::before {
    content: attr(aria-title);
    color: #fff;
    font-size: 12px;
    position: absolute;
    top: 2px;
    left: 11px;
    height: 17px;

    z-index: 1;
  }
  &-two::after {
    background-color: #c1c1c1 !important;
  }
}

.bg-blue {
  background-color: $curious-blue !important;
}

.bg-white {
  background-color: $white-bg-color !important;
}

.bg-fa-player {
  td {
    background-color: $milk-punch-color !important;
  }
}

.width-100 {
  width: 100%;
}

.width-fit {
  width: fit-content;
  min-width: fit-content;
  @include respond(tab-port) {
    width: unset;
    min-width: unset;
  }
}

.width-max-content {
  width: max-content;
  @include respond(tab-land) {
    width: unset;
  }
}

.title-search {
  background-color: $curious-blue;
  color: #fff;
  padding: 12px 10px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  height: 100%;
}

.checkbox-yellow {
  .ant-checkbox-inner {
    height: 17px;
    width: 17px;
    box-shadow: rgba(0, 0, 0, 0.35) 1px 1px 1px;
    &:after {
      height: 12px;
      width: 5px;
      top: 45%;
    }
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: $yellow1;
    border: none;
  }

  .ant-checkbox:hover .ant-checkbox-inner {
    border-color: $yellow1;
  }

  .ant-checkbox-checked::after {
    border: none;
  }
}

.no-padding {
  padding: 2px !important;
  min-height: 1px;
  height: 1px;
}

.pointer {
  cursor: pointer;
}

.icon-start {
  position: absolute;
  left: 0;
}

.max-ch-12 {
  //1character Japan = 2ch
  max-width: 12ch;
  width: max-content;
}

.max-ch-8 {
  //1character Japan = 2ch
  max-width: 8ch;
  width: max-content;
}

.full-cell {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.calendar-picker {
  width: 272px;
  height: 257px;
}

.cell-date-container {
  position: relative;
  height: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cell-date {
  font-weight: bold;
  line-height: 1.3;
}

.ant-picker-cell-today .cell-date {
  text-align: center;
  margin: 0 auto;
  color: $white-color;
  background-color: $black-color;
  width: 21px;
  height: 21px;
  border-radius: 999px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-picker-cell-selected .cell-date-container {
  background-color: $yellow-color;
  border-radius: 999px;
}

.cell-range-date {
  background-color: $yellow-color !important;
  width: 100% !important;
  border-radius: 0px !important;
}

.cell-range-start-date {
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
}

.cell-range-end-date {
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
  .ant-col {
    height: 100%;
  }
}

.border-right-none {
  border-right: none !important;
}

.sort-table {
  .ant-table-column-sorters {
    flex-direction: column;
  }
}

.table-fit-content {
  width: fit-content !important;
  @include respond(tab-port) {
    width: unset !important;
  }
  table {
    // min-width: unset !important;
  }
}
.no-border {
  border: none !important;
}

.width-content-fit {
  width: fit-content;
  max-width: 100%;
}

.nowrap {
  white-space: nowrap;
}

.text-medium {
  font-weight: 500 !important;
}

.text-bold {
  font-weight: 700 !important;
}

.text-bolder {
  font-weight: 900;
}

.btn-csv {
  min-width: 124px;
  width: 124px;
  height: 30px;
}

.scroll-horizon-custom {
  &::-webkit-scrollbar-track {
    // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: $alto-color;
  }

  &::-webkit-scrollbar {
    height: 8px;
    background-color: $alto-color;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: $grey4;
  }
}

.summary-relative {
  position: relative !important;
  z-index: unset !important;
  left: unset !important;
}

.table-h2h {
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr:not(:last-child) > th {
    border-bottom: unset !important;
  }
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > th {
    border-right: 1px dashed #b2b2b2 !important;
    border-bottom: 2px;
  }
}
.ant-modal-mask {
  background-color: rgba(0, 0, 0, 0.25);
}

.bolder {
  font-weight: 900;
}

b,
strong {
  font-weight: bold;
}

div,
label,
td,
th,
span {
  line-height: 1.3;
}

.ant-table-fixed {
  table-layout: fixed;
}

.cell-white {
  background-color: unset !important;
}

.maintenance {
  width: 100%;
  height: 100%;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.maintain-btn:disabled,
.maintain-btn:hover {
  border-color: #b1b1b1 !important;
  background-color: #eeeeee !important;
  color: #b1b1b1 !important;
}
