@import './styles/index';
@import '~antd/dist/antd.css';

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  position: relative;
  color: $black-color !important;
}
